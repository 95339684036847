import React from 'react';

export default class extends React.Component {
  render() {
    return (
      <div className="data-protection">
        <div className="data-protection__inner">
          <h2>Datenschutzerklärung</h2>
          <p>
            Willkommen auf der Website unserer Physiotherapiepraxis. Die nachfolgende Datenschutzerklärung gilt für die
            Nutzung unserer Website sowie der darauf angebotenen Dienstleistungen. Die vorliegende Datenschutzerklärung 
            informiert Sie über die Erhebung personenbezogener Daten während Ihres Besuchs auf dieser Website. Mit der 
            Nutzung dieser Website erklären Sie sich mit der Bearbeitung Ihrer Personendaten gemäss der vorliegenden 
            Datenschutzerklärung einverstanden.
          </p>
          <p>
            Die Erhebung und die Verarbeitung Ihrer personenbezogenen Daten geschehen unter Beachtung der geltenden
            datenschutzrechtlichen Gesetze und Anforderungen. Im Übrigen lehnt die Physiotherapiepraxis jegliche 
            Haftung bei der Nutzung dieser Website ab.
          </p>
          <h3>Erfassung von allgemeinen Daten und Informationen</h3>
          <p>
            Mit jedem Aufruf der Website werden eine Reihe von allgemeinen Daten und Informationen erfasst. Diese 
            allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden 
            können (1) die verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System verwendete 
            Betriebssystem, (3) die Website, von welcher ein zugreifendes System auf unsere Website gelangt 
            (sogenannte Referrer), (4) die Unterwebsites, welche über ein zugreifendes System auf unserer Website 
            angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die Website, (6) eine
            Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden 
            Systems und (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle 
            von Angriffen auf unsere IT-Infrastruktur dienen.
          </p>
          <p>
            Diese Daten und Informationen werden benötigt, um (1) die Inhalte unserer Website korrekt 
            darzustellen, (2) die Inhalte unserer Website zu optimieren, (3) die dauerhafte Funktionsfähigkeit
            unserer IT-Infrastruktur und der Technik unserer Website zu gewährleisten sowie um (4) im Falle 
            eines Cyberangriffes den Strafverfolgungsbehörden die notwendigen Informationen bereitzustellen.
          </p>
          <h3>Links anderer Websites</h3>
          <p>
            Diese Website kann Links zu Websites anderer Anbieter enthalten. Unsere Physiotherapiepraxis hat 
            keinen Einfluss auf diese Websites, deren Inhalte, Angebote oder Verfügbarkeit oder die dort
            geltenden Datenschutzerklärungen und  -bestimmungen. Sie lehnt daher jegliche Haftung ab.
          </p>
          <h3>Cookies und weitere Serviceangebote</h3>
          <p>
            Unsere Physiotherapiepraxis kann auf ihrer Website Cookies verwenden. Cookies enthalten eine 
            sogenannte Cookie-ID, eine eindeutige Kennung des Cookies, welche Websites und Server einem 
            konkreten Internetbrowser zuordnet. Ein bestimmter Internetbrowser kann über diese eindeutige 
            Cookie-ID wiedererkannt und identifiziert werden.
          </p>
          <p>
            Solche Informationen über die Nutzung dieser Website (einschliesslich Ihrer IP-Adresse) können 
            z.B. im Falle von Google Analytics an Server im Ausland übertragen und dort gespeichert werden. 
            Mit der Nutzung unserer Website erklären Sie sich einverstanden mit der Übertragung dieser Daten.
          </p>
          <p>
            Unsere Physiotherapiepraxis kann weitere Serviceangebote von Drittanbietern nutzen, um deren 
            Inhalte und Services wie z.B. Videos oder Beiträge in die Website einzubinden. Eine entsprechende 
            Einbindung setzt immer voraus, dass diese Drittanbieter die IP-Adresse und weitere Informationen 
            der Besucher dieser Website erfassen und verarbeiten können. Mit der Nutzung unserer Website erklären 
            Sie sich damit einverstanden.
          </p>
          <p>
            Auf dieser Website können Schaltflächen («Social-Media-Plugins») platziert sein, die einen Kontakt 
            mit den Servern von sozialen Netzwerken (Facebook, Twitter, Instagram etc.) herstellen. Mit dem 
            Klick auf einen dieser Buttons erteilen Sie Ihre Zustimmung, dass notwendige Daten wie IP-Adresse, 
            Besuch der Website u.a. an den jeweiligen Betreiber des sozialen Netzwerks übermittelt werden.
          </p>
          <h3>Kommunikationsmöglichkeiten</h3>
          <p>
            Die Website unserer Physiotherapiepraxis enthält gewisse Angaben, die eine schnelle elektronische 
            Kontaktaufnahme sowie eine unmittelbare Kommunikation ermöglichen, was ebenfalls eine allgemeine 
            E-Mail-Adresse umfasst. Nehmen Sie über die auf unserer Website angebotenen Kommunikationsmöglichkeiten 
            Kontakt mit uns auf, werden Ihre Kontaktangaben und Inhalte lediglich zur Bearbeitung und Beantwortung 
            Ihrer Anfrage verwendet.
          </p>
          <p>
            Bitte beachten Sie: Es kann nicht sichergestellt werden, dass Ihre Daten vertraulich übermittelt 
            werden. Deshalb sollen bei dieser Kontaktaufnahme keine vertraulichen Daten (medizinische Anliegen etc.) 
            übermittelt werden. 
          </p>
          <h3>Ihre Rechte</h3>
          <p>
            Gestützt auf das Datenschutzgesetz haben Sie verschiedene Rechte wie beispielsweise ein Auskunftsrecht. Zur 
            Geltendmachung Ihrer Rechte setzen Sie sich bitte mit der Physiotherapiepraxis in Verbindung. Wir stehen 
            Ihnen für weitere diesbezügliche Informationen gerne zur Verfügung.
          </p>
          <h3>Änderungen</h3>
          <p>
            Unsere Physiotherapiepraxis kann diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt 
            die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil eines 
            Vertrages mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf 
            andere geeignete Weise informieren.
          </p>
        </div>"
      </div>
    );
  }
}
