import React from 'react';
import Modal from './Modal.jsx';

export default class extends React.Component {
  render() {
    return (
      <Modal title="philosophie">
        <p>
          Gesundheit ist das grösste und wichtigste Gut des Menschen, welches sorgfältig gepflegt werden sollte.
          Dazu bedarf es gezielter Prävention und Rehabilitation. Dem Team von physio balance liegt dies sehr am Herzen
          und wir sind bestrebt, diese Herausforderung täglich bestmöglich umzusetzen.
        </p>
        <p>
          Die individuelle Betreuung und Förderung von alltags spezifischen Abläufen bis hin zur Integration in den
          Sport hat bei uns höchste Priorität. Durch ständiges fachliches Weiterbilden versuchen wir diesen
          Anforderungen gerecht zu werden. Zudem ermöglichen wir ein breites Spektrum an Behandlungsmethoden. Das Team
          von physio balance ergänzt sich optimal durch die unterschiedlichen Schwerpunkte der Zusatzausbildungen der
          einzelnen PhysiotherapeutInnen.
        </p>
      </Modal>
    );
  }
}
