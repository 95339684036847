/* global google */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class GoogleMap extends React.Component {
  componentDidMount() {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': this.props.address}, (results) => {
      const location = results[0].geometry.location;
      this._map = new google.maps.Map(ReactDOM.findDOMNode(this.refs.map), {
        center: location, // Switzerland coordinates
        zoom: 15
      });
      new google.maps.Marker({
        map: this._map,
        position: location,
        title: 'Physio Balance'
      });
    });
  }
  render() {
    return (
      <div className="googlemap" ref="map" />
    );
  }
}

GoogleMap.propTypes = {
  address: PropTypes.string.isRequired
};
